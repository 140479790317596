import {
  emptyKeyedResponseData,
  LandingPageContent,
  KeyedResponseData,
  Relationship,
  Theme,
  SEO,
} from '../../global/types'
import { Block } from '../../utils/renderer'

export type DenormalizedField = {
  id: string
  name: string
  text: string
  enabled: boolean
  type: string
  fieldType: string
  settings: {
    validations: null | string[]
  }
  orderIndex: number
}

export type LandingPageContentType = {
  id: string
  content: {
    version: number
    structure: Block
  }
  language: {
    id: string
    name: string
  }
  fields: DenormalizedField[]
  workflow: {
    id?: string
    rules?: string
  }
  theme: {
    id: string
    logoIcon: string
  }
  vehicles: {
    id: string
    name: string
    text: string
    enabled: boolean
    cities: string[]
  }[]
  seo: SEO
}

export type DenormalizedLandingPage = {
  id: string
  name: string
  baseUrl: string
  landingPageDomain: string
  landingPageContents: LandingPageContentType[]
  theme: Theme
  privacyPolicyUrl: string
  cookiePolicyUrl: string
  country: { id: string }
}

// Function to glue resources together and solve relationships
export const getLandingPageRequestDenormalizer = (
  data: KeyedResponseData,
  landingPageId: string,
  changes: KeyedResponseData = emptyKeyedResponseData,
): DenormalizedLandingPage | null => {
  if (!data.landingPages || !data.landingPages[landingPageId]) return null

  // eslint-disable-next-line
  let resolvedLandingPage: any = data.landingPages[landingPageId]

  const landingPageChanges =
    changes.landingPages && changes.landingPages[resolvedLandingPage.id]
      ? changes.landingPages[resolvedLandingPage.id]
      : {}

  resolvedLandingPage = { ...resolvedLandingPage, ...landingPageChanges }

  // Resolve landing page contents
  resolvedLandingPage.landingPageContents =
    resolvedLandingPage.landingPageContents.map((content: Relationship) => ({
      ...data.landingPageContents[content.id],
      ...(changes.landingPageContents && changes.landingPageContents[content.id]
        ? changes.landingPageContents[content.id]
        : {}),
    }))

  resolvedLandingPage.theme = data.themes[resolvedLandingPage.theme.id]

  resolvedLandingPage.landingPageContents =
    resolvedLandingPage.landingPageContents.map(
      (content: LandingPageContent) => ({
        ...content,
        ...{ language: data.languages[content.language.id] },
        fields: content.fields.map(field => ({
          ...data.fields[field.id],
          ...(changes.fields && changes.fields[field.id]
            ? changes.fields[field.id]
            : {}),
        })),
        vehicles: content.vehicles.map(vehicle => ({
          ...data.vehicles[vehicle.id],
          ...(changes.vehicles && changes.vehicles[vehicle.id]
            ? changes.vehicles[vehicle.id]
            : {}),
        })),
        seo: {
          ...data.seos[content.seo.id],
          ...(changes.seos && changes.seos[content.seo.id]
            ? changes.seos[content.seo.id]
            : {}),
        },
        workflow: {
          ...data.workflows[content.workflow.id],
          ...(changes.workflows && changes.workflows[content.workflow.id]
            ? changes.workflows[content.workflow.id]
            : {}),
        },
      }),
    )

  return resolvedLandingPage
}
