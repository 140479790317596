import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import {
  SideSheet,
  SideSheetHeader,
  Typography,
  Tab,
  SideSheetBody,
  Button,
  styled,
  SideSheetFooter,
} from '@deliveryhero/armor'

import { Tabs, TabView } from '../../../components/armor-overrides'
import { KeyedResponseData } from '../../../global/types'
import { DenormalizedLandingPage } from '../selectors'

import { GeneralTab } from './GeneralTab'
import { LanguageConfig } from './LanguageConfig'
import { LocationsTab } from './LocationsTab'
import { SEOTab } from './SEOTab'
import { VehiclesTab } from './VehiclesTab'

const SaveButton = styled(Button)`
  float: right;
`

const BorderedTabView = styled(TabView)`
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 2.5rem;
  margin-bottom: 1.5rem;
  min-height: 85%;
`

const TabsWithMargin = styled(Tabs)`
  margin-bottom: 1.5rem;
  position: sticky;
  top: 0;
  background: #fff;
  width: 100%;
  z-index: 1;
`
const TAB_INDEXES = {
  GENERAL: 0,
  LANGUAGES: 1,
  LOCATIONS: 2,
  VEHICLES: 3,
  SEO: 4,
}

type IProps = {
  isOpen: boolean
  landingPage: DenormalizedLandingPage
  handleChanges: (newChanges: KeyedResponseData) => void
  toggleSidebar: () => void
  changes: KeyedResponseData
  onAddNewField: () => void
  onSaveChanges: () => void
}

export const SettingsSideSheet: React.FC<IProps> = ({
  isOpen,
  landingPage,
  handleChanges,
  changes,
  toggleSidebar,
  onSaveChanges,
  onAddNewField,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(0)
  const [selectedTab, setSelectedTab] = useState(0)
  const [t] = useTranslation()

  const handleFieldChange = (
    _,
    id: string,
    property: string,
    value: string | { validations: string[] },
  ) => {
    const newId = id || uuid()
    const newChanges: KeyedResponseData = {
      fields: {
        [newId]: {
          restOperation: id ? 'PATCH' : 'POST',
          [property]: value,
        },
      },
    }
    handleChanges(newChanges)
  }

  const handleVehicleChange = (id: string, property: string, value: string) => {
    const newId = id || uuid()
    const newChanges: KeyedResponseData = {
      vehicles: {
        [newId]: {
          restOperation: id ? 'PATCH' : 'POST',
          [property]: value,
        },
      },
    }
    handleChanges(newChanges)
  }

  const handleLandingPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChanges({
      landingPages: {
        [landingPage.id]: {
          [e.target.name]: e.target.value,
          restOperation: 'PATCH',
        },
      },
    })
  }

  const availableLanguages = landingPage.landingPageContents.map(
    content => content.language,
  )

  const areWorkflowRulesValid = () => {
    // will allow old format, and new format "name$locationId$workflowId" and "name$locationId" (workflowId is a uuid)
    const regex =
      // eslint-disable-next-line max-len
      /^(([\w\-\s]+)\$(\w+):(\w+):(\w+))|(([\w\-\s]+)\$(\w+):(\w+):(\w+)\$([0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}));$/

    return !Object.keys(changes.workflows).some(
      workflowId =>
        !!changes.workflows[workflowId].rules
          .trim()
          .split('\n')
          .some(rule => !regex.test(rule)),
    )
  }

  const isAllowedToSave = () =>
    Object.keys(changes).length > 0 &&
    (!changes.workflows || areWorkflowRulesValid())

  return (
    <SideSheet open={isOpen} onClose={toggleSidebar}>
      {/* Temporary inline style cause styled not working here :( */}
      <SideSheetHeader style={{ paddingLeft: '0.5rem' }} title="Settings" />
      <SideSheetBody>
        <TabsWithMargin>
          <Tab
            onClick={() => {
              setSelectedTab(TAB_INDEXES.GENERAL)
            }}
          >
            <Typography>General</Typography>
          </Tab>
          <Tab
            onClick={() => {
              setSelectedTab(TAB_INDEXES.LANGUAGES)
            }}
          >
            <Typography>Languages</Typography>
          </Tab>
          <Tab
            onClick={() => {
              setSelectedTab(TAB_INDEXES.LOCATIONS)
            }}
          >
            <Typography>Locations</Typography>
          </Tab>
          <Tab
            onClick={() => {
              setSelectedTab(TAB_INDEXES.VEHICLES)
            }}
          >
            <Typography>Vehicles</Typography>
          </Tab>
          <Tab
            onClick={() => {
              setSelectedTab(TAB_INDEXES.SEO)
            }}
          >
            <Typography>SEO</Typography>
          </Tab>
        </TabsWithMargin>
        <BorderedTabView
          value={TAB_INDEXES.GENERAL}
          selectedValue={selectedTab}
        >
          <GeneralTab
            landingPage={landingPage}
            handleLandingPageChange={handleLandingPageChange}
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
            availableLanguages={availableLanguages}
            handleFieldChange={handleFieldChange}
            onAddNewField={onAddNewField}
          />
        </BorderedTabView>
        <BorderedTabView
          value={TAB_INDEXES.LANGUAGES}
          selectedValue={selectedTab}
        >
          <LanguageConfig landingPage={landingPage} />
        </BorderedTabView>

        <BorderedTabView
          value={TAB_INDEXES.LOCATIONS}
          selectedValue={selectedTab}
        >
          <LocationsTab
            landingPage={landingPage}
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
            availableLanguages={availableLanguages}
            handleChanges={handleChanges}
          />
        </BorderedTabView>
        <BorderedTabView
          value={TAB_INDEXES.VEHICLES}
          selectedValue={selectedTab}
        >
          <VehiclesTab
            landingPage={landingPage}
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
            availableLanguages={availableLanguages}
            handleVehicleChange={handleVehicleChange}
          />
        </BorderedTabView>
        <BorderedTabView value={TAB_INDEXES.SEO} selectedValue={selectedTab}>
          <SEOTab
            landingPage={landingPage}
            seo={landingPage.landingPageContents[selectedLanguage].seo}
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
            availableLanguages={availableLanguages}
            handleChanges={handleChanges}
          />
        </BorderedTabView>
      </SideSheetBody>
      <SideSheetFooter style={{ justifyContent: 'flex-end' }}>
        <SaveButton disabled={!isAllowedToSave()} onClick={onSaveChanges}>
          {t('Settings.General.SaveSettings')}
        </SaveButton>
      </SideSheetFooter>
    </SideSheet>
  )
}
