import React from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Typography } from '@deliveryhero/armor'

import { Language, KeyedResponseData } from '../../../global/types'
import { DenormalizedLandingPage } from '../selectors'

import { LanguageTabs } from './LanguageTabs'
import { LocationRule, LocationsInput } from './LocationsInput/LocationsInput'
import { rulesToString } from './LocationsInput/utils'

type IProps = {
  landingPage: DenormalizedLandingPage
  setSelectedLanguage: (index: number) => void
  selectedLanguage: number
  availableLanguages: Language[]
  handleChanges: (newChanges: KeyedResponseData) => void
}

export const LocationsTab: React.FC<IProps> = ({
  landingPage,
  availableLanguages,
  setSelectedLanguage,
  selectedLanguage,
  handleChanges,
}) => {
  const [t] = useTranslation()
  const { workflow } = landingPage.landingPageContents[selectedLanguage]

  const onLocationChange = (rules: LocationRule[]) => {
    handleChanges({
      workflows: {
        [workflow.id]: {
          rules: rulesToString(rules),
          restOperation: 'PATCH',
        },
      },
    })
  }
  return (
    <Grid>
      <Typography paragraph medium>
        {t('Settings.Workflow.Description')}
      </Typography>

      <LanguageTabs
        languages={availableLanguages}
        selectedLanguage={selectedLanguage}
        onSelectLanguage={setSelectedLanguage}
        render={() => (
          <LocationsInput
            onRuleChange={onLocationChange}
            countryId={landingPage.country.id}
            rules={workflow?.rules}
          />
        )}
      />
    </Grid>
  )
}
