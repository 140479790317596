import { t } from 'i18next'
import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormField,
  FormFieldMessage,
  styled,
  TextInput,
} from '@deliveryhero/armor'

import { validVideoProvider } from '../../../utils/video'

type VideoDialogProps = {
  initalVideoURL: string
  onSave: (u: string) => void
  onCancel: VoidFunction
}

const CustomDialogTitle = styled(DialogTitle)`
  padding-bottom: 0;
  margin-bottom: -32px;
`

const CustomDialogActions = styled(DialogActions)`
  padding-top: 24px !important;
`

export const VideoDialog = ({
  onSave,
  onCancel,
  initalVideoURL,
}: VideoDialogProps) => {
  const [videoURL, setVideoURL] = React.useState(initalVideoURL)
  const validVideo = validVideoProvider(videoURL)
  const inputError = videoURL !== '' && !validVideo
  return (
    <Dialog open onClose={onCancel} width="441px">
      <CustomDialogTitle description={t('LandingPage.VideoDialogDescription')}>
        {t('LandingPage.VideoDialogTitle')}
      </CustomDialogTitle>
      <DialogContent paddingTop="1em">
        <FormField>
          <TextInput
            wide
            name="videoURL"
            label={t('LandingPage.VideoDialogInputLabel')}
            value={videoURL}
            onChange={e => {
              setVideoURL(e.target.value)
            }}
            error={inputError}
          />
          {inputError && (
            <FormFieldMessage error oneLine>
              {t('LandingPage.VideoDialogError')}
            </FormFieldMessage>
          )}
        </FormField>
      </DialogContent>
      <CustomDialogActions>
        <Button tertiary onClick={onCancel}>
          {t('LandingPage.VideoDialogCancel')}
        </Button>
        <Button
          tag="label"
          primary
          onClick={() => onSave(videoURL)}
          disabled={!validVideo}
        >
          {t('LandingPage.VideoDialogAdd')}
        </Button>
      </CustomDialogActions>
    </Dialog>
  )
}
