import React, { useEffect, useState } from 'react'

import { Checkbox, Flex, List, ListItem, Skeleton } from '@deliveryhero/armor'

import { AraraLocation, useGetLocations } from '../../arara.api'

import { stringToRules } from './utils'

type IProps = {
  rules: string | undefined
  countryId: string
  onRuleChange: (rule: LocationRule[]) => void
}

export type LocationRule = {
  name: string
  locationId: string
  workflow: string | undefined
}
export const LocationsInput = ({ rules, countryId, onRuleChange }: IProps) => {
  const [originalRules, setOriginalRules] = useState<LocationRule[]>([])
  const [selectedRules, setSelectedRules] = useState<LocationRule[]>([])

  useEffect(() => {
    if (!rules) {
      return
    }
    setSelectedRules(stringToRules(rules))
    setOriginalRules(stringToRules(rules))
  }, [rules])

  const { locations, loading } = useGetLocations(countryId)

  if (loading) {
    return (
      <List wide>
        {Array.from(Array(10)).map((it, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={index} enableSecondaryItem={false}>
            <Flex>
              <Skeleton animated /> <Skeleton animated width="250px" />
            </Flex>
          </ListItem>
        ))}
      </List>
    )
  }

  const handleSelectedRuleChange = (
    checked: boolean,
    location: AraraLocation,
  ) => {
    const newRulesSelected = checked
      ? [
          ...selectedRules,
          // workflow will be removed as pages are updated to use the arara locations
          // we keep it in the type just to give it visibility.
          {
            locationId: location.id,
            name: location.name,
            workflow:
              originalRules.find(it => it.locationId === location.id)
                ?.workflow ?? undefined,
          },
        ]
      : selectedRules.filter(it => it.locationId !== location.id)

    setSelectedRules(newRulesSelected)
    onRuleChange(newRulesSelected)
  }

  return (
    <List wide>
      {locations &&
        locations.map(it => (
          <ListItem key={it.id} enableSecondaryItem={false}>
            <Checkbox
              checked={selectedRules.some(sr => sr.locationId === it.id)}
              onChange={e => {
                handleSelectedRuleChange(e.target.checked, it)
              }}
              label={it.id && it.name ? `${it.id} - ${it.name}` : ''}
              marginBottom={2}
            />
          </ListItem>
        ))}
    </List>
  )
}
