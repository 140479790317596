import { LocationRule } from './LocationsInput'

export const stringToRules = (s: string | undefined): LocationRule[] => {
  if (!s?.replace(/\s/g, '')) {
    return []
  }

  const splitRules = s
    .trim()
    .replace(/[\r\n]/gm, '')
    .split(';')
    .filter(it => it)
    .map(it => it.split('$'))

  return splitRules.map(([name, locationId, workflow]) => ({
    name,
    locationId: locationId.replace(';', ''),
    workflow: workflow?.replace(';', ''),
  }))
}

export const rulesToString = (r: LocationRule[]) =>
  r
    .map(
      it =>
        `${it.name}$${it.locationId}${it.workflow ? `$${it.workflow}` : ''};`,
    )
    .join('\n')
