import cloneDeep from 'lodash.clonedeep'
import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { Grid, Container, styled } from '@deliveryhero/armor'

import { LoadingSpinner } from '../../components/Atoms/LoadingSpinner'
import { LandingPageRenderer } from '../../components/LandingPageRenderer'
import {
  LandingPage,
  KeyedResponseData,
  Relationship,
} from '../../global/types'
import { mergeNewChanges } from '../../utils/handleChanges'
import { usePrevious } from '../../utils/hooks'
import { optimisedImage } from '../../utils/imageCompression'
import { resolvePathInObject, setValueInPath } from '../../utils/object-path'
import {
  BLOCK_TYPES,
  EVENTS_TYPE,
  unregisterEvents,
  registerEvents,
  EventCallback,
  Block,
  Image,
} from '../../utils/renderer'
import { persistChangesInServer, uploadFile } from '../../utils/requests'

import SubNavBar from './SubNavBar/SubNavBar'
import { revalidateLandingPage, useGetLandingPage, useGetThemes } from './api'
import { createLandingPageField } from './factories/factories'
import { PopoverEditor } from './popover-editor/PopoverEditor'
import { PopoverNewField } from './popover-new-field/PopoverNewField'
import { Preview } from './preview'
import {
  DenormalizedLandingPage,
  getLandingPageRequestDenormalizer,
} from './selectors'
import { SettingsSideSheet } from './settings-sidesheet'
import { ThemesSidebar } from './themes-sidebar/ThemesSidebar'

type IProps = {
  landingPageId: string
}

const GridContainer = styled(Grid)`
  background-color: #f2f2f2;
  min-height: calc(100vh - 96px);
  padding-top: 16px;
`

const StructureContainer = styled(Container)`
  margin-top: 2.25rem;
  padding: 0 3.25rem 0 3.25rem;
`

let pendingUploadingImages = []

const LandingPageEditor: React.FC<IProps> = ({ landingPageId }) => {
  const [changes, setChanges] = useState({})
  const [isConfigSidebarOpen, setIsConfigSidebarOpen] = useState(false)
  const [isThemeSidebarOpen, setIsThemeSidebarOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [objectPath, setObjectPath] = useState(null)
  const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(0)
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [selectedLanguageNewField, setSelectedLanguageNewField] = useState(0)
  const [isFileUploadingStarted, setIsFileUploadingStarted] = useState(false)
  const isFileUploadingStartedPrev = usePrevious(isFileUploadingStarted)
  const [isAddNewFieldPopoverOpen, setIsAddNewFieldPopoverOpen] =
    useState(false)

  const { data: normalizedLandingPage, loading: landingPageLoading } =
    useGetLandingPage(landingPageId)
  const { data: themes, loading: themesLoading } = useGetThemes()

  const landingPage =
    normalizedLandingPage && !landingPageLoading && !themesLoading
      ? getLandingPageRequestDenormalizer(
          { ...(normalizedLandingPage as Record<string, unknown>), themes },
          landingPageId,
          changes,
        )
      : undefined

  const commitChangesInServer = useCallback(
    () =>
      persistChangesInServer(changes)
        .then(() => {
          setChanges({})
          revalidateLandingPage(landingPageId)
        })
        .catch(err => console.error({ err })),
    [landingPageId, changes],
  )

  const eventHandlers = useCallback(
    (landingPageInCallback: DenormalizedLandingPage): EventCallback =>
      (type, elementId, path) => {
        if (!elementId || !landingPageInCallback) return
        const editableBlocks = [
          BLOCK_TYPES.TEXT_BLOCK,
          BLOCK_TYPES.IMAGE_BLOCK,
          BLOCK_TYPES.VIDEO_BLOCK,
          BLOCK_TYPES.TEXT_LIST,
        ]
        let activeBlock: Block | undefined
        switch (type) {
          case EVENTS_TYPE.CLICK:
            setAnchorEl(document.querySelector(`[data-id="${elementId}"]`))
            activeBlock = resolvePathInObject(
              landingPageInCallback.landingPageContents[selectedLanguageIndex]
                .content.structure,
              path,
            ) as Block

            if (!activeBlock || !editableBlocks.includes(activeBlock.type))
              break
            setObjectPath(path)
            break
          case EVENTS_TYPE.MOUSE_OVER:
            break
          default:
        }
      },
    [selectedLanguageIndex],
  )

  useEffect(() => {
    registerEvents(eventHandlers(landingPage))
    return () => unregisterEvents(eventHandlers(landingPage))
  }, [landingPage, eventHandlers])

  useEffect(() => {
    const isFileUploadingStartedAndFinished =
      !isFileUploadingStarted && isFileUploadingStartedPrev

    if (isFileUploadingStartedAndFinished) {
      commitChangesInServer()
    }
  }, [
    changes,
    isFileUploadingStarted,
    isFileUploadingStartedPrev,
    commitChangesInServer,
  ])

  const resourcesStillLoading = !landingPage || isFileUploadingStarted

  if (resourcesStillLoading)
    return (
      <div
        // Intentionally inlined since there seems to be a style
        // loading issue on first load
        style={{
          display: 'grid',
          justifyContent: 'center',
          alignContent: 'center',
          height: '100vh',
        }}
      >
        <LoadingSpinner width="50px" />
      </div>
    )

  const handleChanges = (newChanges: KeyedResponseData) =>
    setChanges(mergeNewChanges(changes, newChanges))

  const toggleSidebar = () => setIsConfigSidebarOpen(!isConfigSidebarOpen)

  const PersistImagesToS3 = () => {
    const paths: string[] = []
    const newBlocks: Block[] = []

    return new Promise<void>(resolve => {
      Promise.all(
        pendingUploadingImages.map(({ params }) =>
          optimisedImage(params).then(uploadFile),
        ),
      )
        .then(responses => {
          // based on the fact that Promise.all preserve the order
          responses.forEach(({ value }, index) => {
            const { oldBlock, path } = pendingUploadingImages[index]
            paths.push(path)
            newBlocks.push({
              ...oldBlock,
              value,
              type: BLOCK_TYPES.IMAGE_BLOCK,
            })
          })
          onBlocksChange(newBlocks, paths)
          resolve()
        })
        .catch(err => console.error({ err }))
    })
  }

  const updatePendingUploadImages = (image: Image) => {
    // Filter all the properties that we don't want to save in the server
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { file, path, ...props } = image

    const {
      file: { name },
    } = image

    const params = {
      type: props.type,
      enabled: true,
      landingPageContentId:
        landingPage.landingPageContents[selectedLanguageIndex].id,
      name,
      value: props.value,
      contents: file,
    }

    pendingUploadingImages.push({
      path: objectPath,
      params,
      oldBlock: props,
    })
  }

  const onSaveChanges = async () => {
    if (pendingUploadingImages.length > 0) {
      setIsFileUploadingStarted(true)
      await PersistImagesToS3()
      pendingUploadingImages = [] // reset for upcoming uploads
      setIsFileUploadingStarted(false)
      return
    }

    commitChangesInServer()
  }

  const onSelectedTheme = (themeId: string) =>
    handleChanges({
      landingPages: {
        [landingPage.id]: {
          theme: {
            id: themeId,
            restRealKey: 'theme_id',
          } as Relationship,
          restOperation: 'PATCH',
        } as LandingPage,
      },
    })

  const onBlocksChange = (blocks: Block[], paths?: string[]) => {
    let newLPStructure = cloneDeep(
      landingPage.landingPageContents[selectedLanguageIndex].content.structure,
    )

    blocks.forEach((block, index) => {
      newLPStructure = setValueInPath(
        newLPStructure,
        `${paths ? paths[index] : objectPath}`,
        block,
      )
    })

    handleChanges({
      landingPageContents: {
        [landingPage.landingPageContents[selectedLanguageIndex].id]: {
          restOperation: 'PATCH',
          content: {
            version:
              landingPage.landingPageContents[selectedLanguageIndex].content
                .version,
            structure: newLPStructure,
          },
        },
      },
    })
  }

  const onAddNewField = selectedLanguage => {
    setSelectedLanguageNewField(selectedLanguage)
    toggleIsAddNewFieldPopoverOpen()
  }

  const toggleIsAddNewFieldPopoverOpen = () =>
    setIsAddNewFieldPopoverOpen(!isAddNewFieldPopoverOpen)

  const onAddNewFieldSubmit = (
    name: string,
    fieldType: string,
    settings: Record<string, unknown> = {},
  ) => {
    const newId = uuid()
    const landingPageContentId =
      landingPage.landingPageContents[selectedLanguageNewField].id

    handleChanges({
      fields: {
        [newId]: {
          restOperation: 'POST',
          ...createLandingPageField({
            id: newId,
            name,
            text: name,
            orderIndex:
              landingPage.landingPageContents[selectedLanguageNewField].fields
                .length + 1,
            fieldType,
            landingPageContentId,
            settings,
          }),
        },
      },
      landingPageContents: {
        [landingPageContentId]: {
          // @ts-expect-error Now we use the languageIndex instead of the landingPageContentId
          fields: landingPage.landingPageContents[
            selectedLanguageNewField
          ].fields.concat({
            id: newId,
            resource: 'fields',
          }),
        },
      },
    })
    toggleIsAddNewFieldPopoverOpen()
  }

  return (
    <>
      <SettingsSideSheet
        landingPage={landingPage}
        handleChanges={handleChanges}
        changes={changes}
        isOpen={isConfigSidebarOpen}
        toggleSidebar={toggleSidebar}
        onSaveChanges={onSaveChanges}
        onAddNewField={onAddNewField}
      />

      <PopoverNewField
        isOpen={isAddNewFieldPopoverOpen}
        onCreate={onAddNewFieldSubmit}
        onCancel={toggleIsAddNewFieldPopoverOpen}
      />

      <PopoverEditor
        isOpen={objectPath !== null}
        block={
          objectPath !== null
            ? (resolvePathInObject(
                landingPage.landingPageContents[selectedLanguageIndex].content
                  .structure,
                objectPath,
              ) as Block)
            : null
        }
        anchorEl={anchorEl}
        onClose={() => setObjectPath(null)}
        onBlocksChange={onBlocksChange}
        updatePendingUploadImages={updatePendingUploadImages}
      />

      {themes && Object.keys(themes).length > 0 && (
        <ThemesSidebar
          isOpen={isThemeSidebarOpen}
          onClose={() => setIsThemeSidebarOpen(false)}
          themes={themes}
          selectedThemeId={landingPage.theme.id}
          onSelectedTheme={onSelectedTheme}
        />
      )}

      <SubNavBar
        landingPage={landingPage}
        changes={changes}
        isPreviewOpen={isPreviewOpen}
        setIsPreviewOpen={setIsPreviewOpen}
        isThemeSidebarOpen={isThemeSidebarOpen}
        setIsThemeSidebarOpen={setIsThemeSidebarOpen}
        onSaveChanges={onSaveChanges}
        toggleSidebar={toggleSidebar}
      />

      <Preview
        isOpen={isPreviewOpen}
        selectedLandingPageContent={selectedLanguageIndex}
        landingPage={landingPage}
        onClose={() => setIsPreviewOpen(false)}
      />
      <GridContainer>
        <StructureContainer>
          <section
            style={{ maxWidth: 900, margin: '0 auto' }}
            className="landing-page-root"
          >
            <LandingPageRenderer
              landingPage={landingPage}
              selectedLandingPageContentIdx={selectedLanguageIndex}
              onChangeLandingPageContextIdx={setSelectedLanguageIndex}
              previewMode
            />
          </section>
        </StructureContainer>
      </GridContainer>
    </>
  )
}

export default LandingPageEditor
